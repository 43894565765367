import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import notifications from '@/store/notifications'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import createPersistedState from 'vuex-persistedstate'

const CardRepository = RepositoryFactory.get('card')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showNotificationDialog: false
  },
  getters: {},
  mutations: {
    setShowNotificationDialog (state, value) {
      state.showNotificationDialog = value
    }
  },
  actions: {
    async sellCard ({ commit }, data) {
      const cardRequest = await CardRepository.sell(data)
      commit('setCard', cardRequest.data)
    }
  },
  modules: {
    auth,
    notifications
  },
  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
