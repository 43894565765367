import { post, get } from '@/api'

const resource = 'auth'

export default {
  async preLogin (data) {
    return post(`${resource}/pre-login`, data)
  },
  async login (data) {
    return post(`${resource}/login`, data)
  },
  async logout () {
    return get(`${resource}/logout`, {})
  }
}
