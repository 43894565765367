<template>
  <v-dialog
    v-model="referralListDialog"
    persistent
    max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="baseColors.main"
        dark
        v-bind="attrs"
        v-on="on"
        @click="loadReferrals"
      >
        Referidos
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5 main-font">Mis referidos</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="referredStores"
          :loading="referredStoresLoader"
          item-key="id"
          :items-per-page="5"
        >
          <template v-slot:item.id="{ item }">
            <v-btn
              dark
              small
              :color="baseColors.main"
              @click="filterStore(item)"
            >
              <v-icon dark>
                mdi-cash-multiple
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="baseColors.secondary"
          dark
          @click="referralListDialog = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const StoreFactory = RepositoryFactory.get('store')
export default {
  name: 'ReferralsList',
  data () {
    return {
      referredStoresLoader: false,
      referredStores: [],
      referralListDialog: false,
      headers: [
        {
          text: 'Nombre',
          value: 'nombre',
          sortable: false
        },
        {
          text: 'Nit',
          value: 'nit',
          sortable: false
        },
        {
          text: 'Dirección',
          value: 'direccion',
          sortable: false
        },
        {
          text: 'Correo',
          value: 'correo',
          sortable: false
        },
        {
          text: 'Celular',
          value: 'celular',
          sortable: false
        },
        {
          text: 'Ventas',
          value: 'id',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async loadReferrals () {
      this.referredStoresLoader = true
      const response = await StoreFactory.referrals()
      this.referredStores = response.data.data
      this.referredStoresLoader = false
    },
    filterStore (item) {
      this.$emit('filter-store', { name: item.nombre })
      this.referralListDialog = false
    }
  }
}
</script>
