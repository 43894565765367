import Vue from 'vue'
import VueRouter from 'vue-router'
import SellCard from '../views/card/SellCard.vue'
import GiftCard from '../views/card/GiftCard.vue'
import RedeemCard from '../views/card/RedeemCard.vue'
import StoreSales from '@/views/store/StoreSales'
import StoreReferrals from '@/views/store/StoreReferrals'
import StoreRedemptions from '@/views/store/StoreRedemptions'
import Login from '@/views/Login'
import accessMiddleware from '@/middlewares/AccessMiddleware'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SellCard',
    component: SellCard,
    meta: { requiresAuth: true }
  },
  {
    path: '/gift/:identificacion',
    name: 'GiftCard',
    component: GiftCard
  },
  {
    path: '/redeem',
    name: 'RedeemCard',
    component: RedeemCard,
    meta: { requiresAuth: true }
  },
  {
    path: '/sales',
    name: 'StoreSales',
    component: StoreSales,
    meta: { requiresAuth: true }
  },
  {
    path: '/referrals',
    name: 'StoreReferrals',
    component: StoreReferrals,
    meta: { requiresAuth: true }
  },
  {
    path: '/redemptions',
    name: 'StoreRedemptions',
    component: StoreRedemptions,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(accessMiddleware)

export default router
