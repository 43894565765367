import { post, get, put } from '@/api'

const resource = 'user'

export default {
  async create (data) {
    return post(`${resource}`, data)
  },
  async update (data) {
    return put(`${resource}`, data)
  },
  async show (parameter) {
    return get(`${resource}/${parameter}`)
  },
  async receiver (data) {
    return post(`${resource}/receiver`, data)
  }
}
