<template>
  <v-data-table
    :headers="headers"
    :items="cards"
    :items-per-page="10"
    class="elevation-1 mt-5"
  >
    <template v-slot:item.saldo="{ item }">
      {{ item.saldo | toCurrency }}
    </template>
    <template v-slot:item.fecha_compra="{ item }">
      {{ item.fecha_compra | toDate }}
    </template>
    <template v-slot:item.fecha_ultima_redencion="{ item }">
      {{ item.fecha_ultima_redencion | toDate }}
    </template>
    <template v-slot:item.id="{ item }">
      <v-btn
        dark
        small
        :color="baseColors.main"
        @click="selectCard(item)"
      >
        <v-icon dark>
          mdi-gift
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'CardList',
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Tienda',
          align: 'center',
          value: 'store.nombre'
        },
        {
          text: 'Receptor',
          align: 'center',
          value: 'receiver_user.nombre',
          sortable: false
        },
        {
          text: 'Tipo',
          align: 'center',
          value: 'tipo',
          sortable: false
        },
        {
          text: 'Saldo',
          align: 'center',
          value: 'saldo',
          sortable: true
        },
        {
          text: 'Descuento',
          align: 'center',
          value: 'descuento',
          sortable: true
        },
        {
          text: 'Fecha compra',
          align: 'center',
          value: 'fecha_compra',
          sortable: true
        },
        {
          text: 'Última redención',
          align: 'center',
          value: 'fecha_ultima_redencion',
          sortable: true
        },
        {
          text: 'Redimir',
          value: 'id',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  methods: {
    selectCard (card) {
      this.$emit('card-selected', { card: card })
    }
  }
}
</script>

<style scoped>

</style>
