import AuthRepository from '@/repositories/AuthRepository'
import OtpRepository from '@/repositories/OtpRepository'
import CardRepository from '@/repositories/CardRepository'
import UserRepository from '@/repositories/UserRepository'
import CategoryRepository from '@/repositories/CategoryRepository'
import StoreRepository from '@/repositories/StoreRepository'
import BusinessTypeRepository from '@/repositories/BusinessTypeRepository'
import CityRepository from '@/repositories/CityRepository'
import RedemptionRepository from '@/repositories/RedemptionRepository'

const repositories = {
  auth: AuthRepository,
  otp: OtpRepository,
  card: CardRepository,
  user: UserRepository,
  store: StoreRepository,
  category: CategoryRepository,
  businessType: BusinessTypeRepository,
  city: CityRepository,
  redemption: RedemptionRepository
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
