var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtrar por fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filterRedemptionsForm.dateRange),callback:function ($$v) {_vm.$set(_vm.filterRedemptionsForm, "dateRange", $$v)},expression:"filterRedemptionsForm.dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerDialog),callback:function ($$v) {_vm.datePickerDialog=$$v},expression:"datePickerDialog"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.filterRedemptionsForm.dateRange),callback:function ($$v) {_vm.$set(_vm.filterRedemptionsForm, "dateRange", $$v)},expression:"filterRedemptionsForm.dateRange"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":['Regalo', 'Tiquetera'],"label":"Filtrar por tipo"},model:{value:(_vm.filterRedemptionsForm.cardType),callback:function ($$v) {_vm.$set(_vm.filterRedemptionsForm, "cardType", $$v)},expression:"filterRedemptionsForm.cardType"}})],1),_c('v-col',{staticClass:"d-flex align-content-end justify-end",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{class:[_vm.baseColors.main, 'mr-2'],attrs:{"dark":""},on:{"click":_vm.loadRedemptions}},[_vm._v("Filtrar")]),_c('v-btn',{staticClass:"white",on:{"click":_vm.cleanFilters}},[_vm._v("Limpiar filtros")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.redemptions,"options":_vm.options,"server-items-length":_vm.totalRedemptions,"items-per-page":10,"item-key":"id","loading":_vm.loader,"single-expand":true,"footer-props":{'disable-items-per-page': true, 'items-per-page-options': []}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-alert',{staticClass:"text-center",attrs:{"outlined":"","color":_vm.baseColors.main}},[_vm._v(" Valor total redimido: "),_c('strong',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.totalValue)))])])]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.created_at))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.valor))+" ")]}},{key:"item.saldo_inicial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.saldo_inicial))+" ")]}},{key:"item.saldo_resultante",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.saldo_resultante))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }