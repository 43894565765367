<template>
  <div>
    <the-page-header>
      <template v-slot:icon>mdi-account-heart</template>
      <template v-slot:text>Vender una tarjeta</template>
      <template v-slot:description>
        Completa los datos del usuario comprador y personaliza los datos de la tarjeta. Recuerda elegir el tipo de tarjeta,
        ya sea de regalo o tiquetera.
      </template>
    </the-page-header>
    <sell-card-form></sell-card-form>
  </div>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader'
import SellCardForm from '@/components/card/sell/SellCardForm'
import { mapState } from 'vuex'

export default {
  name: 'SellCard',
  components: {
    ThePageHeader,
    SellCardForm
  },
  computed: {
    ...mapState('auth', ['store'])
  }
}
</script>

<style scoped>

</style>
