<template>
  <div>
    <the-page-header>
      <template v-slot:icon>mdi-home-group-plus</template>
      <template v-slot:text>Reporte de referidos</template>
      <template v-slot:description>
        Aquí podrás gestionar todo lo relacionado con referenciar tiendas, podrás registrar una nueva tienda,
        ver las que tienes registradas y además ver el reporte de ventas de todas las tiendas que hayas creado.
      </template>
    </the-page-header>
    <v-row class="mt-16">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title class="d-flex justify-end flex-wrap">
            <register-referral-form
              :logged-store-id="store.id"
              @store-created="storeCreated"
            ></register-referral-form>
            <referrals-list @filter-store="filterStore"></referrals-list>
          </v-card-title>
          <v-card-text>
            <referred-store-card-sales :filter-store-name="filterStoreName"></referred-store-card-sales>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <base-notification-dialog></base-notification-dialog>
  </div>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader'
import RegisterReferralForm from '@/components/store/referrals/RegisterReferralForm'
import BaseNotificationDialog from '@/components/notifications/BaseNotificationDialog'
import ReferralsList from '@/components/store/referrals/ReferralsList'
import ReferredStoreCardSales from '@/components/store/referrals/ReferredStoreCardSales'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StoreReferrals',
  components: {
    ThePageHeader,
    RegisterReferralForm,
    BaseNotificationDialog,
    ReferralsList,
    ReferredStoreCardSales
  },
  data () {
    return {
      filterStoreName: ''
    }
  },
  computed: {
    ...mapState('auth', ['store'])
  },
  methods: {
    ...mapActions('notifications', ['displayNotificationDialogWithMessage']),
    storeCreated (payload) {
      this.displayNotificationDialogWithMessage(payload.message)
    },
    filterStore (payload) {
      this.filterStoreName = payload.name
    }
  }
}
</script>
