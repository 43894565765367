<template>
  <v-dialog
    v-model="displayNotificationDialog"
    persistent
    max-width="300px"
  >
    <v-card>
      <v-card-title class="text-h5 pink accent-1 mb-3 white--text">
        INFORMACIÓN
      </v-card-title>
      <v-card-text>
        {{ notificationDialogMessage }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="baseColors.main"
          text
          @click="closeNotificationDialog"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BaseNotificationDialog',
  computed: {
    ...mapState('notifications', ['displayNotificationDialog', 'notificationDialogMessage'])
  },
  methods: {
    ...mapActions('notifications', ['closeNotificationDialog'])
  }
}
</script>

<style scoped>

</style>
