<template>
  <div>
    <the-page-header>
      <template v-slot:icon>mdi-cash</template>
      <template v-slot:text>Registro de ventas</template>
      <template v-slot:description>
        Aquí pordrás ver el historial de ventas tanto de tarjetas como de tiqueteras, así como también el
        historial de redención de la misma.
      </template>
    </the-page-header>
    <v-row class="pt-8">
      <v-col cols="12" md="12">
        <v-card elevation="51">
          <v-card-title>
            <v-row>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="datePickerDialog"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterCardsForm.dateRange"
                      label="Filtrar por fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterCardsForm.dateRange"
                    range
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="filterCardsForm.cardType"
                  :items="['Regalo', 'Tiquetera']"
                  label="Filtrar por tipo"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" class="justify-end text-right">
                <v-text-field
                  v-model="filterCardsForm.buyerUser"
                  label="Filtrar por usuario"
                  placeholder="Nombre, correo o identificación"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="d-flex align-content-end justify-end">
                <v-btn :class="[baseColors.main, 'mr-2']" dark @click="loadCards">Filtrar</v-btn>
                <v-btn class="white" @click="cleanFilters">Limpiar filtros</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="cards"
            :options.sync="options"
            :server-items-length="totalCards"
            :items-per-page="10"
            item-key="id"
            :loading="loader"
            :single-expand="true"
            :footer-props="{'disable-items-per-page': true, 'items-per-page-options': []}"
          >
            <template v-slot:top>
              <v-alert
                class="text-center"
                outlined
                :color="baseColors.main"
              >
                Total vendido: <strong>{{ totalValue | toCurrency }}</strong>
              </v-alert>
            </template>
            <template v-slot:item.valor="{ item }">
                {{ item.valor | toCurrency }}
            </template>
            <template v-slot:item.saldo="{ item }">
              {{ item.saldo | toCurrency }}
            </template>
            <template v-slot:item.fecha_compra="{ item }">
              {{ item.fecha_compra | toDate }}
            </template>
            <template v-slot:item.descuento="{ item }">
              {{ item.descuento }}%
            </template>
            <template v-slot:item.detalle_redencion="{ item }">
              <v-btn
                v-if="item.redemptions.length > 0"
                dark
                small
                :color="baseColors.main"
                @click="loadRedemptionHistory(item.redemptions)"
              >
                <v-icon dark>
                  mdi-history
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="redemptionHistoryDialog"
      max-width="600px"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 main-font justify-center">
          Historial de redenciones
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-timeline>
            <v-timeline-item
              v-for="redemptionItem in redemptionHistory"
              :key="redemptionItem.created_at"
              :color="baseColors.main"
            >
              <h3 slot="opposite">{{ redemptionItem.store.nombre }} <br>
                <small class="text-disabled">{{ redemptionItem.created_at | toDate }}</small>
              </h3>
              <v-card class="elevation-2">
                <v-card-text>
                  Redención por un valor de <strong>{{ redemptionItem.valor | toCurrency }}</strong>. Referencia: <strong>{{redemptionItem.referencia }}</strong>.
                  Con un motivo de: <strong>{{ redemptionItem.descripcion}}</strong>. Tuvo un saldo inicial de <strong>{{ redemptionItem.saldo_inicial | toCurrency }}</strong>
                  y un saldo final de <strong>{{ redemptionItem.saldo_resultante | toCurrency }}</strong>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="baseColors.main"
            text
            @click="closeRedemptionDialog"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const CardRepository = RepositoryFactory.get('card')
export default {
  name: 'StoreSales',
  components: {
    ThePageHeader
  },
  data () {
    return {
      headers: [
        {
          text: 'ID Tarjeta',
          align: 'center',
          value: 'id',
          sortable: false
        },
        {
          text: 'Identificación',
          align: 'center',
          value: 'buyer_user.identificacion',
          sortable: false
        },
        {
          text: 'Comprador',
          align: 'center',
          value: 'buyer_user.nombre',
          sortable: false
        },
        {
          text: 'Valor',
          value: 'valor',
          sortable: false
        },
        {
          text: 'Saldo',
          value: 'saldo',
          sortable: false
        },
        {
          text: 'Fecha compra',
          value: 'fecha_compra',
          sortable: false
        },
        {
          text: 'Tipo',
          value: 'tipo',
          sortable: false
        },
        {
          text: 'Descuento',
          value: 'descuento',
          align: 'center',
          sortable: false
        },
        {
          text: 'Última redención',
          value: 'fecha_ultima_redencion',
          sortable: false
        },
        {
          text: 'Redenciones',
          value: 'detalle_redencion',
          align: 'center',
          sortable: false
        }
      ],
      filterCardsForm: {
        dateRange: [],
        buyerUser: '',
        cardType: ''
      },
      cards: [],
      loader: false,
      datePickerDialog: false,
      redemptionHistoryDialog: false,
      redemptionHistory: [],
      page: 1,
      options: {},
      totalCards: 0,
      totalValue: 0
    }
  },
  watch: {
    options: {
      handler () {
        this.loadCards()
      },
      deep: true
    }
  },
  methods: {
    async loadCards () {
      this.loader = true
      try {
        const request = await CardRepository.search({
          page: this.options.page,
          type: this.filterCardsForm.cardType,
          dates: this.filterCardsForm.dateRange.join(','),
          buyerUser: this.filterCardsForm.buyerUser
        })
        this.cards = request.data.data.data
        this.totalCards = request.data.data.total
        this.totalValue = request.data.data.total_value
      } catch (e) {}
      this.loader = false
    },
    loadRedemptionHistory (redemptionHistory) {
      this.redemptionHistoryDialog = true
      this.redemptionHistory = redemptionHistory.reverse()
    },
    closeRedemptionDialog () {
      this.redemptionHistoryDialog = false
      this.redemptionHistory = []
    },
    cleanFilters () {
      this.filterCardsForm.dateRange = []
      this.filterCardsForm.cardType = ''
      this.filterCardsForm.buyerUser = ''
      this.loadCards()
    }
  }
}
</script>

<style scoped>

</style>
