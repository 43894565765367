export const sellCardValidations = {
  name: [
    name => (name && !!name) || 'El nombre es requerido',
    name => name.length >= 6 || 'El nombre debe ser mayor a 6 caracteres'
  ],
  email: [
    email => (email && !!email) || 'El correo es requerido',
    email => /.+@.+/.test(email) || 'Debe ingresar un correo válido'
  ],
  identification: [
    id => (id && !!id) || 'La identificación es requerida',
    id => (id.length >= 4 && id.length <= 12 && !isNaN(id)) || 'Formato de identificación inválida'
  ],
  cellphone: [
    cellphone => (cellphone && !!cellphone) || 'El celular es requerido',
    cellphone => (
      !isNaN(cellphone) &&
        cellphone.length > 9 &&
        cellphone.length < 11 &&
        !Number.isInteger(cellphone)) ||
      'El formato del celular es incorrecto'
  ],
  cardValue: [
    cardValue => (cardValue && !!cardValue) || 'El valor de la tarjeta es requerido',
    value => /^\d+(,\d+)*$/.test(value) || 'El valor no debe contener carácteres especiales'
  ],
  otp: [
    otp => (otp && !!otp) || 'Por favor ingresa el código de seguridad'
  ],
  cardType: [
    cardType => (cardType && !!cardType) || 'Por favor ingresa el tipo'
  ]
}
