<template>
  <v-btn
    :color="baseColors.main"
    :loading="loader"
    dark
    tile
    @click="$emit('submission')"
  >
    <template v-slot:loader>
      <span class="custom-loader">
        <v-icon light>mdi-cached</v-icon>
      </span>
    </template>
    {{textContent}}
  </v-btn>
</template>

<script>
export default {
  name: 'BaseSubmitButton',
  props: {
    textContent: {
      type: String,
      required: true
    },
    loader: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
