var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.cards,"items-per-page":10},scopedSlots:_vm._u([{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.saldo))+" ")]}},{key:"item.fecha_compra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.fecha_compra))+" ")]}},{key:"item.fecha_ultima_redencion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.fecha_ultima_redencion))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","small":"","color":_vm.baseColors.main},on:{"click":function($event){return _vm.selectCard(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-gift ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }