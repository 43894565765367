import { post, get } from '@/api'

const resource = 'card'

export default {
  async search (data) {
    return get(`${resource}/search`, data)
  },
  async sell (data) {
    return post(`${resource}/buy`, data)
  },
  async redeem (data) {
    return post(`${resource}/redeem`, data)
  },
  async show (redemptionCode) {
    return get(`${resource}/${redemptionCode}`, null)
  },
  async gift (data) {
    return post(`${resource}/gift`, data)
  }
}
