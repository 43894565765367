export default async function accessMiddleware (
  to,
  from,
  next
) {
  const isAuthenticated = localStorage.getItem('accessToken') ?? false
  if (to.path === '/login' && isAuthenticated) {
    next('/')
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
}
