<template>
  <v-row :class="rowClass">
    <v-col :cols="columnSize">
      <v-card flat>
        <v-card-text>
          <v-otp-input
            label="Código de seguridad"
            :color="baseColors.main"
            v-model="otp"
            :disabled="otpLoader"
            @finish="redeemOTP"
            inputmode="numeric"
          >
          </v-otp-input>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="requestOTP()"
            :loading="otpLoader"
            :disabled="otpLoader"
            style="font-size: 13px"
          >
            {{text}}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import ErrorHelper from '@/helpers/ErrorHelper'

const OtpRepository = RepositoryFactory.get('otp')

export default {
  name: 'OTPTextField',
  props: {
    columnSize: {
      type: String,
      default: 'md-6'
    },
    text: {
      type: String,
      default: 'Solicitar código de seguridad'
    },
    rowClass: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      required: true
    },
    id: {
      required: true,
      default: undefined
    },
    redeemOnFinish: {
      type: Boolean,
      default: true,
      required: false
    },
    requestOnCreated: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  created () {
    if (this.requestOnCreated) {
      this.requestOTP()
    }
  },
  data () {
    return {
      otp: '',
      otpLoader: false,
      otpResponseMessage: '',
      validations: {
        otp: [
          otp => !!otp || 'Por favor ingresa el código de seguridad'
        ]
      }
    }
  },
  methods: {
    async requestOTP () {
      this.otpLoader = true
      await OtpRepository.generate({ id: this.id, to: this.to }).then(response => {
        this.otpResponseMessage = response.data.data.message
      }).catch(error => {
        if (error.request.status === 422) {
          this.otpResponseMessage = ErrorHelper.formatErrorsAsArray(error)[0]
        }
      })
      this.$emit('otp-requested', { otpResponseMessage: this.otpResponseMessage })
      this.otpLoader = false
      this.otp = ''
    },
    async redeemOTP () {
      if (!this.redeemOnFinish) {
        this.$emit('otp-redeemed', { success: true, otp: this.otp })
        this.otp = ''
        return
      }
      this.otpLoader = true
      let otpIsValid = false
      await OtpRepository.redeem({ codigo: this.otp, tipo: this.to }).then(response => {
        otpIsValid = true
        this.otpResponseMessage = response.data.data.message
      }).catch(error => {
        if (error.request.status === 422) {
          this.otpResponseMessage = ErrorHelper.formatErrorsAsArray(error)[0]
        }
      })
      this.otpLoader = false
      this.otp = ''
      this.$emit('otp-redeemed', { success: otpIsValid, otpResponseMessage: this.otpResponseMessage })
    }
  }
}
</script>

<style scoped>

</style>
