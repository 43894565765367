<template>
  <v-card
    class="mt-5"
    elevation="10"
  >
    <v-card-title>
      <div v-html="getTextHeader()"></div>
      <strong class="ml-2">Saldo: <span class="pink--text ml-1"> {{ currentCard.saldo | toCurrency }}</span></strong>
    </v-card-title>
    <v-divider></v-divider>

    <v-window v-model="step" touchless>
      <v-window-item :value="1">
        <card-info-window :card="currentCard"></card-info-window>
      </v-window-item>
      <v-window-item :value="2">
        <redeem-card-form-window
          :redemption-code="currentCard.codigo_redencion"
          @card-redeemed="handleFormSubmission"
        ></redeem-card-form-window>
      </v-window-item>
      <v-window-item :value="3">
        <update-user-info-form-window
          :user-information="currentCard.receiver_user"
          :redemption-code="currentCard.codigo_redencion"
          @user-updated="handleFormSubmission"
        ></update-user-info-form-window>
      </v-window-item>
    </v-window>
    <v-card-actions>
      <v-btn
        :disabled="step === 1"
        tile
        @click="step = 1"
      >
        Información
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="card.saldo > 0"
        :color="baseColors.main"
        @click="step = 2"
        :disabled="step === 2"
        dark
        tile
      >
        Redimir
      </v-btn>
      <v-btn
        color="light-blue lighten-2"
        :disabled="step === 3"
        dark
        tile
        @click="step = 3"
      >
        Cliente
      </v-btn>
    </v-card-actions>
    <base-notification-dialog></base-notification-dialog>
  </v-card>
</template>

<script>
import CardInfoWindow from '@/components/card/redeem/card-windows/CardInfoWindow'
import RedeemCardFormWindow from '@/components/card/redeem/card-windows/RedeemCardFormWindow'
import UpdateUserInfoFormWindow from '@/components/card/redeem/card-windows/UpdateUserInfoFormWindow'
import BaseNotificationDialog from '@/components/notifications/BaseNotificationDialog'
import { mapActions } from 'vuex'
export default {
  name: 'RedeemCardForm',
  components: {
    CardInfoWindow,
    RedeemCardFormWindow,
    UpdateUserInfoFormWindow,
    BaseNotificationDialog
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      step: 1,
      cardInfo: null
    }
  },
  computed: {
    currentCard () {
      if (this.cardInfo) {
        return this.cardInfo
      }
      return this.card
    }
  },
  methods: {
    ...mapActions('notifications', ['displayNotificationDialogWithMessage']),
    getTextHeader () {
      return this.currentCard.tipo === 'Tiquetera' ? 'Tiquetera Comprada por:<span class="pink--text mx-2">' + this.currentCard.buyer_user.nombre + '</span>'
        : 'Tarjeta Comprada por: <span class="pink--text mx-2">' + this.currentCard.buyer_user.nombre + '</span>\n' +
        'para <span class="pink--text ml-2">' + this.currentCard.receiver_user.nombre + '</span>.'
    },
    /**
     * This can handle both card redeemed and user updated form submission, as the
     * payload should be similar and the actions to perform afterwards is the same
     * @param payload
     */
    handleFormSubmission (payload) {
      if (payload.success) {
        this.cardInfo = payload.card
        this.step = 1
      }
      this.$emit('close-card-windows', payload.closeCardWindows)
      this.displayNotificationDialogWithMessage(payload.message)
    }
  }
}
</script>

<style scoped>

</style>
