import { post, get } from '@/api'

const resource = 'store'

export default {
  async create (data) {
    return post(`${resource}/create`, data, false, true)
  },
  async referrals () {
    return get(`${resource}/referrals`)
  }
}
