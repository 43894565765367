<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-menu
          v-model="datePickerDialog"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterCardsForm.dateRange"
              label="Filtrar por fecha"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterCardsForm.dateRange"
            range
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="filterCardsForm.cardType"
          :items="['Regalo', 'Tiquetera']"
          label="Filtrar por tipo"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" class="justify-end text-right">
        <v-text-field
          v-model="filterCardsForm.store"
          label="Filtrar por tienda"
          placeholder="Nombre, correo o NIT"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" class="d-flex align-content-end justify-end">
        <v-btn :class="[baseColors.main, 'mr-2']" dark @click="loadCards">Filtrar</v-btn>
        <v-btn class="white" @click="cleanFilters">Limpiar filtros</v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="referredStoreCards"
          :options.sync="options"
          :server-items-length="totalCards"
          :items-per-page="10"
          item-key="id"
          :loading="loader"
          :single-expand="true"
          :footer-props="{'disable-items-per-page': true, 'items-per-page-options': []}"
        >
          <template v-slot:top>
            <v-alert
              class="text-center"
              outlined
              :color="baseColors.main"
            >
              Total vendido: <strong>{{ totalValue | toCurrency }}</strong>
            </v-alert>
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.valor | toCurrency }}
          </template>
          <template v-slot:item.saldo="{ item }">
            {{ item.saldo | toCurrency }}
          </template>
          <template v-slot:item.fecha_compra="{ item }">
            {{ item.fecha_compra | toDate }}
          </template>
          <template v-slot:item.descuento="{ item }">
            {{ item.descuento }}%
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const CardRepository = RepositoryFactory.get('card')
export default {
  name: 'ReferredStoreCardSales',
  props: {
    filterStoreName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      filterCardsForm: {
        dateRange: [],
        cardType: '',
        store: ''
      },
      headers: [
        {
          text: 'Tienda',
          value: 'store.nombre',
          sortable: false
        },
        {
          text: 'Valor',
          value: 'valor',
          sortable: false
        },
        {
          text: 'Saldo',
          value: 'saldo',
          sortable: false
        },
        {
          text: 'Fecha compra',
          value: 'fecha_compra',
          sortable: false
        },
        {
          text: 'Tipo',
          value: 'tipo',
          sortable: false
        }
      ],
      referredStoreCards: [],
      loader: false,
      datePickerDialog: false,
      page: 1,
      options: {},
      totalCards: 0,
      totalValue: 0
    }
  },
  watch: {
    options: {
      handler () {
        this.loadCards()
      },
      deep: true
    },
    async filterStoreName (newStoreName) {
      this.filterCardsForm.store = newStoreName
      await this.loadCards()
    }
  },
  methods: {
    async loadCards () {
      this.loader = true
      try {
        const request = await CardRepository.search({
          page: this.options.page,
          type: this.filterCardsForm.cardType,
          dates: this.filterCardsForm.dateRange.join(','),
          store: this.filterCardsForm.store,
          referredStoreSales: 1
        })
        this.referredStoreCards = request.data.data.data
        this.totalCards = request.data.data.total
        this.totalValue = request.data.data.total_value
      } catch (e) {}
      this.loader = false
    },
    cleanFilters () {
      this.filterCardsForm.dateRange = []
      this.filterCardsForm.cardType = ''
      this.filterCardsForm.store = ''
      this.loadCards()
    }
  }
}
</script>
