<template>
  <v-dialog
    v-model="showNotificationDialog"
    persistent
    max-width="300px"
  >
    <v-card>
      <v-card-title class="text-h5 pink accent-1 mb-3 white--text">
        INFORMACIÓN
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="baseColors.main"
          text
          @click="close"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'NotificationDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    ...mapState(['showNotificationDialog'])
  },
  methods: {
    ...mapMutations(['setShowNotificationDialog']),
    close () {
      this.setShowNotificationDialog(false)
    }
  }
}
</script>

<style scoped>

</style>
