<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-menu
          v-model="datePickerDialog"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterRedemptionsForm.dateRange"
              label="Filtrar por fecha"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterRedemptionsForm.dateRange"
            range
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="filterRedemptionsForm.cardType"
          :items="['Regalo', 'Tiquetera']"
          label="Filtrar por tipo"
        ></v-select>
      </v-col>
      <v-col cols="12" md="12" class="d-flex align-content-end justify-end">
        <v-btn :class="[baseColors.main, 'mr-2']" dark @click="loadRedemptions">Filtrar</v-btn>
        <v-btn class="white" @click="cleanFilters">Limpiar filtros</v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="redemptions"
          :options.sync="options"
          :server-items-length="totalRedemptions"
          :items-per-page="10"
          item-key="id"
          :loading="loader"
          :single-expand="true"
          :footer-props="{'disable-items-per-page': true, 'items-per-page-options': []}"
        >
          <template v-slot:top>
            <v-alert
              class="text-center"
              outlined
              :color="baseColors.main"
            >
              Valor total redimido: <strong>{{ totalValue | toCurrency }}</strong>
            </v-alert>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | toDate }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.valor | toCurrency }}
          </template>
          <template v-slot:item.saldo_inicial="{ item }">
            {{ item.saldo_inicial | toCurrency }}
          </template>
          <template v-slot:item.saldo_resultante="{ item }">
            {{ item.saldo_resultante | toCurrency }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const RedemptionRepository = RepositoryFactory.get('redemption')
export default {
  name: 'RedemptionList',
  data () {
    return {
      headers: [
        {
          text: 'Tararjeta vendida en',
          value: 'card.store.nombre',
          sortable: false
        },
        {
          text: 'Fecha redención',
          value: 'created_at',
          sortable: false
        },
        {
          text: 'Referencia',
          value: 'referencia',
          sortable: false
        },
        {
          text: 'Descripción',
          value: 'descripcion',
          sortable: false
        },
        {
          text: 'Valor',
          value: 'valor',
          sortable: false
        },
        {
          text: 'Saldo Inicial',
          value: 'saldo_inicial',
          sortable: false
        },
        {
          text: 'Saldo Resultante',
          value: 'saldo_resultante',
          sortable: false
        },
        {
          text: 'Tipo tarjeta',
          value: 'card.tipo',
          sortable: false
        }
      ],
      filterRedemptionsForm: {
        dateRange: [],
        cardType: ''
      },
      loader: false,
      totalValue: 0,
      totalRedemptions: 0,
      redemptions: [],
      page: 1,
      options: {},
      datePickerDialog: false
    }
  },
  watch: {
    options: {
      handler () {
        this.loadRedemptions()
      },
      deep: true
    }
  },
  methods: {
    async loadRedemptions () {
      this.loader = true
      try {
        const request = await RedemptionRepository.search({
          page: this.options.page,
          dates: this.filterRedemptionsForm.dateRange.join(','),
          type: this.filterRedemptionsForm.cardType
        })
        this.redemptions = request.data.data.data
        this.totalRedemptions = request.data.data.total
        this.totalValue = request.data.data.total_value
      } catch (e) {}
      this.loader = false
    },
    cleanFilters () {
      this.filterRedemptionsForm.dateRange = []
      this.filterRedemptionsForm.cardType = ''
      this.loadRedemptions()
    }
  }
}
</script>

<style scoped>

</style>
