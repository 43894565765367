<template>
  <v-dialog
    v-model="formDialog"
    persistent
    max-width="700px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="baseColors.secondary"
        :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          color="white"
        >
          mdi-plus
        </v-icon>
        Registrar
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5 main-font">Registrar tienda referida</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="referralForm">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="referralForm.name"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-autocomplete
                  v-model="referralForm.city"
                  :loading="cities.loader"
                  :items="cities.items"
                  :search-input.sync="cities.search"
                  cache-items
                  hide-no-data
                  hide-details
                  label="Ciudad"
                  item-text="nombre"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="referralForm.nit"
                  label="NIT"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="referralForm.phone"
                  label="Teléfono"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="referralForm.email"
                  label="Correo"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="referralForm.address"
                  label="Dirección"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-select
                  v-model="referralForm.businessType"
                  label="Tipo negocio"
                  :items="businessTypes"
                  item-text="nombre"
                  item-value="id"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-file-input
                  v-model="referralFormFiles.chamberOfCommerce.value"
                  label="Cámara de comercio"
                  show-size
                  truncate-length="8"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-file-input
                  v-model="referralFormFiles.rut.value"
                  label="Rut"
                  show-size
                  truncate-length="8"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-file-input
                  v-model="referralFormFiles.identification.value"
                  label="Cedula"
                  show-size
                  truncate-length="8"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-file-input
                  v-model="referralFormFiles.publicServices.value"
                  label="Factura servicios públicos"
                  show-size
                  truncate-length="8"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="baseColors.secondary"
          dark
          @click="formDialog = false"
        >
          Cerrar
        </v-btn>
        <base-submit-button
          :loader="formLoader"
          text-content="Registrar"
          @submission="registerReferredStore"
        ></base-submit-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSubmitButton from '@/components/form/BaseSubmitButton'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import ErrorHelper from '@/helpers/ErrorHelper'
import debounce from 'lodash/debounce'

const StoreRepository = RepositoryFactory.get('store')
const CityRepository = RepositoryFactory.get('city')
const BusinessTypeRepository = RepositoryFactory.get('businessType')
export default {
  name: 'RegisterReferralForm',
  components: {
    BaseSubmitButton
  },
  props: {
    loggedStoreId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formDialog: false,
      formLoader: false,
      referralForm: {
        name: null,
        nit: null,
        email: null,
        phone: null,
        address: null,
        businessType: null,
        city: null
      },
      referralFormFiles: {
        rut: {
          value: null,
          name: 'archivo_rut'
        },
        chamberOfCommerce: {
          value: null,
          name: 'archivo_camara_de_comercio'
        },
        identification: {
          value: null,
          name: 'archivo_cedula'
        },
        publicServices: {
          value: null,
          name: 'archivo_servicios_publicos'
        }
      },
      businessTypes: [],
      cities: {
        items: [],
        loader: false,
        search: null
      }
    }
  },
  watch: {
    'cities.search': function (val) {
      this.delayedSearchCities(val)
    }
  },
  async created () {
    const request = await BusinessTypeRepository.get()
    this.businessTypes = request.data.data
  },
  methods: {
    async registerReferredStore () {
      this.formLoader = true
      try {
        const response = await StoreRepository.create(this.buildCreateStoreRequestParam())
        const createdStore = response.data.data
        this.$refs.referralForm.reset()
        this.$emit('store-created', {
          success: true,
          message: 'Se ha creado la tienda con exito',
          createdStore: createdStore
        })
        this.formDialog = false
      } catch (error) {
        this.$emit('store-created', {
          success: false,
          message: ErrorHelper.formatErrorsAsArray(error)[0],
          createdStore: null
        })
      }
      this.formLoader = false
    },
    delayedSearchCities: debounce(function (param) {
      if (param && param.length >= 2) {
        this.searchCities(param)
      }
    }, 600),
    async searchCities (param) {
      this.cities.loader = true
      try {
        const response = await CityRepository.search(param)
        this.cities.items = response.data.data
      } catch (e) {
      }
      this.cities.loader = false
    },
    buildCreateStoreRequestParam () {
      const formData = new FormData()

      const formFileFields = [
        'rut',
        'chamberOfCommerce',
        'identification',
        'publicServices'
      ]

      formFileFields.forEach(fieldName => {
        if (this.referralFormFiles[fieldName].value) {
          formData.append(this.referralFormFiles[fieldName].name, this.referralFormFiles[fieldName].value)
        }
      })

      const {
        name,
        nit,
        email,
        phone,
        address,
        businessType,
        city
      } = this.referralForm

      formData.append('nombre', name)
      formData.append('nit', nit)
      formData.append('correo', email)
      formData.append('celular', phone)
      formData.append('direccion', address)
      formData.append('id_tienda_referente', this.loggedStoreId)
      formData.append('id_tipo_negocio', businessType)
      formData.append('id_ciudad', city)
      return formData
    }
  }
}
</script>

<style scoped>

</style>
