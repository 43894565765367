<template>
  <v-container class="login-container">
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-card
          class="mx-auto"
          max-width="500"
          :loading="preLoginLoader"
          elevation="10"
        >
          <template slot="progress">
            <v-progress-linear
              :color="baseColors.main"
              height="8"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title class="text-h6 font-weight-regular justify-space-between">
            <span class="main-font" style="font-size: 23px">
              {{ currentTitle }}
            </span>
            <v-avatar
              :color="baseColors.main"
              class="subheading white--text"
              size="24"
              v-text="loginStep"
            ></v-avatar>
          </v-card-title>
          <v-divider></v-divider>
          <v-window v-model="loginStep">
            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  v-model="loginForm.correo"
                  :color="baseColors.main"
                  label="Correo"
                  clearable
                >
                  <v-icon
                    slot="prepend"
                    :color="baseColors.main"
                  >
                    mdi-at
                  </v-icon>
                </v-text-field>
                <v-text-field
                  v-model="loginForm.password"
                  :color="baseColors.main"
                  label="Password"
                  type="password"
                  clearable
                  @keyup.enter="validatePreLogin"
                >
                  <v-icon
                    slot="prepend"
                    :color="baseColors.main"
                  >
                    mdi-lock
                  </v-icon>
                </v-text-field>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <OTPTextField
                :id="preLoginStoreId"
                to="id_comercio"
                columnSize="md-12"
                :redeem-on-finish="false"
                @otp-requested="otpRequested"
                @otp-redeemed="checkLogin"
               :request-on-created="true">
              </OTPTextField>
            </v-window-item>
          </v-window>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="loginStep !== 2"
              :color="baseColors.main"
              depressed
              :loading="preLoginLoader"
              @click="validatePreLogin"
              dark
            >
              Validar datos
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <NotificationDialog
      :message="alertMessage">
    </NotificationDialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import NotificationDialog from '@/components/dialogs/NotificationDialog'
import OTPTextField from '@/components/authentication/OTPTextField'

export default {
  name: 'Login',
  components: {
    NotificationDialog,
    OTPTextField
  },
  data () {
    return {
      loginStep: 1,
      storeId: null,
      alertMessage: '',
      preLoginLoader: false,
      loginForm: {
        correo: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['loginErrors', 'preLoginStoreId']),
    loginHasErrors () {
      return this.loginErrors.length > 0
    },
    currentTitle () {
      return this.loginStep === 1 ? 'Ingresa tus datos' : 'Validar código'
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'preLogin']),
    ...mapMutations(['setShowNotificationDialog']),
    async validatePreLogin () {
      this.preLoginLoader = true
      await this.preLogin(this.loginForm)
      if (this.loginHasErrors) {
        this.alertMessage = this.loginErrors[0]
        this.setShowNotificationDialog(true)
      } else {
        this.alertMessage = ''
        this.loginStep = 2
      }
      this.preLoginLoader = false
    },
    otpRequested (payload) {
      this.setShowNotificationDialog(true)
      this.alertMessage = payload.otpResponseMessage
    },
    async checkLogin (payload) {
      this.preLoginLoader = true
      const { correo, password } = this.loginForm
      await this.login({ correo: correo, password: password, otp: payload.otp })
      if (this.loginHasErrors) {
        this.alertMessage = this.loginErrors[0]
        this.setShowNotificationDialog(true)
      }
      this.preLoginLoader = false
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.login-title {
  margin-bottom: 10px;
}

.login-line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
}

.button-group > * {
  margin: 0 5px;
}

/* Personaliza los iconos */
.custom-icon-color {
  color: pink !important;
}
</style>
