<template>
  <div>
    <v-card
      class="mt-10"
      :loading="loader"
    >
      <template slot="progress">
        <v-progress-linear
          :color="baseColors.main"
          height="8"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-form
        ref="redeemForm"
        v-model="searchCardForm.isValid"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-window v-model="searchCardStep">
                <v-window-item :value="1">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="searchCardForm.userHint"
                          label="Teléfono / Correo / Identificación"
                          :color="baseColors.main"
                          clearable
                          class="text-center"
                        >
                          <v-icon
                            slot="prepend"
                            :color="baseColors.main"
                          >
                            mdi-account
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="searchCardForm.redemptionCode"
                          label="Número de Tarjeta/Tiquetera"
                          :color="baseColors.main"
                          clearable
                          class="text-center"
                          inputmode="numeric"
                        >
                          <v-icon
                            slot="prepend"
                            :color="baseColors.main"
                          >
                            mdi-gift
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="text-center mt-3">
                        <v-btn :color="baseColors.main" dark @click="validateData">
                          <v-icon
                            color="white"
                          >
                            mdi-magnify
                          </v-icon>
                          Validar datos
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item :value="2" :key="resetOtpInputCounter">
                  <OTPTextField
                    :id="otpUserId"
                    text="Volver a solicitar"
                    to="id_usuario_receptor"
                    column-size="md-6"
                    row-class="justify-center"
                    @otp-requested="otpRequested"
                    @otp-redeemed="otpRedeemed"
                    :request-on-created="true"
                  >
                  </OTPTextField>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-if="searchCardStep === 2">
          <v-btn :color="baseColors.main" dark @click="searchCardStep = 1">
            <v-icon
              color="white"
            >
              mdi-arrow-left-thin
            </v-icon>
            Atras
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <base-notification-dialog></base-notification-dialog>
    <card-list
      v-if="displayCardList"
      id="cardListContainer"
      :cards="userCards"
      @card-selected="selectCard"
    >
    </card-list>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OTPTextField from '@/components/authentication/OTPTextField'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import CardList from '@/components/card/redeem/CardList'
import BaseNotificationDialog from '@/components/notifications/BaseNotificationDialog'
const UserRepository = RepositoryFactory.get('user')
const CardRepository = RepositoryFactory.get('card')

export default {
  name: 'SearchCardsForm',
  components: {
    CardList,
    OTPTextField,
    BaseNotificationDialog
  },
  props: {
    storeId: {
      type: Number,
      required: true
    },
    storeGroupId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      notificationMessage: '',
      resetOtpInputCounter: 0,
      loader: false,
      searchCardStep: 1,
      searchCardForm: {
        redemptionCode: null,
        userHint: null,
        isValid: false
      },
      userCards: [],
      selectedCard: null,
      otpUserId: null,
      displayCardList: false
    }
  },
  methods: {
    ...mapActions('notifications', ['displayNotificationDialogWithMessage']),
    async validateData () {
      this.prepareDataValidation()
      if (!this.searchCardForm.userHint && !this.searchCardForm.redemptionCode) {
        this.displayNotificationDialogWithMessage('Por favor ingresa la información del usuario o el código de redención.')
        return false
      }
      this.loader = true
      if (this.searchCardForm.userHint) {
        await this.fetchUserCards()
      }
      if (this.searchCardForm.redemptionCode) {
        await this.fetchCard()
      }
      this.loader = false
      if (this.otpUserId) {
        this.searchCardStep = 2
      }
    },
    prepareDataValidation () {
      this.$emit('validate-data-request')
      this.displayCardList = false
      this.selectedCard = null
      this.userCards = []
      this.otpUserId = null
      this.notificationMessage = ''
    },
    async fetchUserCards () {
      try {
        let user = await UserRepository.show(this.searchCardForm.userHint)
        user = user.data.data
        this.userCards = user.received_cards.filter(card => this.validateUserCard(card, user))
        if (this.userCards.length === 0) {
          this.notificationMessage = 'Este usuario no tiene tarjetas/Tiqueteras para redimir'
        } else {
          this.otpUserId = user.id
        }
      } catch (e) {
        this.notificationMessage = 'Usuario no encontrado'
      }
      this.displayNotificationDialogWithMessage(this.notificationMessage)
    },
    validateUserCard (card, user) {
      if (card.saldo <= 0 || card.id_usuario_receptor !== user.id) {
        return false
      }
      if (this.storeGroupId !== null) {
        return card.id_comercio === this.storeId || card.store.id_grupo_comercio === this.storeGroupId
      }
      return card.id_comercio === this.storeId
    },
    async fetchCard () {
      try {
        const request = await CardRepository.show(this.searchCardForm.redemptionCode)
        this.selectedCard = request.data.data
        if (this.selectedCard.saldo === 0) {
          this.notificationMessage = 'Esta tarjeta o tiquetera no tiene saldo'
        } else {
          this.otpUserId = this.selectedCard.receiver_user.id
        }
      } catch (error) {
        this.notificationMessage = 'Tarjeta/Tiquetera no encontrada'
      }
      this.displayNotificationDialogWithMessage(this.notificationMessage)
    },
    otpRequested (payload) {
      this.displayNotificationDialogWithMessage(payload.otpResponseMessage)
    },
    otpRedeemed (payload) {
      if (!payload.success) {
        this.displayNotificationDialogWithMessage(payload.otpResponseMessage)
        return false
      }
      // If a redemption code was introduced, emit card selected to redeem
      if (this.selectedCard && this.userCards.length === 0) {
        this.$emit('card-selected', { card: this.selectedCard })
      }
      // Else, display user's available cards
      if (this.userCards.length > 0) {
        this.displayCardList = true
        // Scrolls to the card list container
        this.scrollToElement(document.getElementById('cardListContainer'))
      }
      this.searchCardStep = 1
      this.$refs.redeemForm.reset()
      this.resetOtpInputCounter += 1
    },
    selectCard (payload) {
      this.selectedCard = payload.card
      this.displayCardList = false
      this.$emit('card-selected', { card: this.selectedCard })
    }
  }
}
</script>

<style scoped>

</style>
