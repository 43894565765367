<template>
  <div>
    <the-page-header>
      <template v-slot:icon>mdi-hand-heart</template>
      <template v-slot:text>Regala una Tarjeta</template>
      <template v-slot:description>Completa los pasos e ingresa los datos
        de la persona a la cual le quieres dar el regalo. Previamente debes confirmar tu identidad como se indica a continuación:
      </template>
    </the-page-header>
    <v-alert
      outlined
      type="success"
      prominent
      border="left"
      max-width="1000px"
      class="mx-auto my-12"
      v-if="cardHasBeenSuccessfullyGifted"
    >
      ¡Le has regalado una tarjeta a <strong>{{ giftCardForm.name }}</strong> satisfactoriamente! A su celular (<strong>{{
        giftCardForm.cellphone
      }}</strong>)
      le llegará un mensaje
      con el código de redención para poderlo redimir en la tienda, y si ingresaste el correo <span
      v-if="giftCardForm.email">({{ giftCardForm.email }})</span> , se le enviará también uno con tu mensaje
      personalizado.
    </v-alert>
    <v-alert
      text
      prominent
      type="error"
      icon="mdi-cloud-alert"
      v-if="errors.message !== ''"
    >
      <strong>Información: </strong>{{ errors.message }}
    </v-alert>
    <v-stepper
      v-model="step"
      vertical
      max-width="1000px"
      class="mx-auto mt-8"
      v-if="errors.message === '' && !cardHasBeenSuccessfullyGifted"
      elevation="23"
    >
      <v-stepper-step
        :complete="step > 1"
        step="1"
        :color="baseColors.main"
      >
        <h3>Redime el código</h3>
        <p class="text--accent-1" style="font-size: 13px">
          <strong>Para continuar, solicita el código en el botón <q>Solicitar código de seguridad</q></strong>.
          Este código te llegará a tu correo: <strong style="color: #FF80AB">{{ user.correo | mask }}</strong> y a tu celular:
          <strong style="color: #FF80AB">{{ user.celular | mask }}</strong></p>
      </v-stepper-step>

      <v-stepper-content step="1">
        <OTPTextField
          v-if="user && user.hasOwnProperty('id')"
          :id="user.id"
          to="id_usuario_receptor"
          @otp-requested="handleOTPRequested"
          @otp-redeemed="handleOTPValidated"
         :request-on-created="false"></OTPTextField>
      </v-stepper-content>

      <v-stepper-step
        :complete="step > 2"
        step="2"
        :color="baseColors.main"
      >
        Elije la tarjeta que quieres regalar
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="(card, key) in user.cards" v-bind:key="key"
          >
            <v-card
              :color="selectedIdCard === card.id ? baseColors.main : baseColors.secondary"
              class="mb-12 card"
              height="200px"
              dark
              @click="selectCard(card.id)"
            >
              <v-card-title>
                Tarjeta # {{ key + 1 }}
              </v-card-title>
              <v-divider></v-divider>
              <v-card-subtitle>
                <strong>Comprada el:</strong> {{ card.fecha_compra | toDate }}
              </v-card-subtitle>
              <v-card-text>
                <strong>Valor: {{ card.valor | toCurrency }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-btn
          :color="baseColors.main"
          @click="checkSelectedCard()"
          dark
        >
          Continuar
          <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
          </template>
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="step > 3"
        step="3"
        :color="baseColors.main"
      >
        Ingresa los datos de la persona
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card>
          <v-card-text>
            <v-form
              v-model="valid"
              ref="form"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="giftCardForm.cellphone"
                      :rules="validations.cellphone"
                      :counter="11"
                      label="Celular"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="giftCardForm.name"
                      :rules="validations.name"
                      label="Nombre Completo"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="giftCardForm.email"
                      :rules="validations.email"
                      label="Correo (Opcional)"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="baseColors.main"
              @click="checkGiftedPersonValues()"
              dark
            >
              Continuar
            </v-btn>
            <v-btn
              text
              @click="step = 2">
              Volver
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        step="4"
        :color="baseColors.main">
        ¡Personaliza tu mensaje!
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                sm="12"
                lg="12"
                class="text-right"
              >
                <v-btn
                  @click="messages.dialog = true"
                  :color="baseColors.main"
                  tile
                  dark
                >Elige mensajes por categoría
                </v-btn>
                <v-dialog
                  v-model="messages.dialog"
                  max-width="900"
                >
                  <v-card>
                    <v-card-title class="text-h5 pink accent-1 mb-3 white--text">
                      Elije la categoría y selecciona el mensaje
                    </v-card-title>
                    <v-card-text>
                      <v-slide-group
                        show-arrows
                      >
                        <v-slide-item
                          v-for="cat in categories.all"
                          :key="cat.id"
                          v-slot="{ active, toggle }"
                        >
                          <v-btn
                            class="mx-2"
                            :input-value="active"
                            active-class="pink accent-1 white--text"
                            depressed
                            rounded
                            @click="toggle(); fillMessages(cat.id)"
                          >
                            {{ cat.nombre }}
                          </v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </v-card-text>
                    <v-card-text>
                      <v-progress-linear
                        v-show="categories.loader"
                        indeterminate
                        :color="baseColors.main"
                      ></v-progress-linear>
                      <v-row
                        align="center"
                        v-show="!categories.loader"
                        v-if="Object.keys(categories.messages).length && Object.keys(categories.messages).length">
                        <v-col cols="12" md="12">
                          <v-data-table
                            :headers="[
                                  {
                                    text: 'Mensaje',
                                    align: 'start',
                                    value: 'descripcion',
                                    sortable: false
                                  },
                                  {
                                    text: 'Seleccionar',
                                    value: 'actions',
                                    sortable: false
                                  },
                                ]"
                            :items="categories.messages"
                            :items-per-page="5"
                            class="elevation-1"
                          >
                            <template v-slot:item.descripcion="{ item }">
                              <v-col md="10">
                                {{ item.descripcion }}
                              </v-col>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-btn
                                :color="baseColors.main"
                                small
                                @click="appendMessage(item.descripcion)"
                              >
                                <v-icon
                                  small
                                  color="white"
                                >
                                  mdi-content-copy
                                </v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col>
                          <v-alert
                            border="left"
                            type="info"
                          >No hay nada que mostrar...
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="dark"
                        text
                        @click="messages.dialog = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="12"
                sm="12"
                lg="12"
              >
                <v-textarea
                  label="Mensaje"
                  v-model="giftCardForm.message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-btn
          :color="baseColors.main"
          @click="submitGiftedCard"
          dark
        >
          <v-progress-circular
            v-if="loaders.giftCard"
            indeterminate
            color="dark"
            :size="20"
            style="margin-right: 10px;"
          ></v-progress-circular>
          Regalar Tarjeta
        </v-btn>
        <v-btn
          text
          @click="step = 3">
          Volver
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    <NotificationDialog :message="dialog.message" :show="dialog.show"></NotificationDialog>
  </div>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import OTPTextField from '@/components/authentication/OTPTextField'
import NotificationDialog from '@/components/dialogs/NotificationDialog'
import { mapMutations, mapState } from 'vuex'

const UserRepository = RepositoryFactory.get('user')
const CategoryRepository = RepositoryFactory.get('category')
const CardRepository = RepositoryFactory.get('card')

export default {
  name: 'GiftCard',
  components: {
    ThePageHeader,
    OTPTextField,
    NotificationDialog
  },
  data () {
    return {
      step: 1,
      user: [],
      valid: false,
      selectedIdCard: null,
      cardHasBeenSuccessfullyGifted: false,
      categories: {
        loader: false,
        all: [],
        messages: []
      },
      messages: {
        dialog: false,
        categories: [],
        content: []
      },
      dialog: {
        message: ''
      },
      loaders: {
        giftCard: false,
        continue: false
      },
      errors: { message: '' },
      lastCreatedUser: {},
      giftCardForm: {
        name: '',
        email: '',
        message: '',
        cellphone: '',
        otp: ''
      },
      validations: {
        name: [
          name => !!name || 'El nombre es requerido',
          name => name.length >= 6 || 'El nombre debe ser mayor a 6 caracteres'
        ],
        email: [
          email => (!email || /.+@.+/.test(email)) || 'Debe ingresar un correo válido'
        ],
        cellphone: [
          cellphone => !!cellphone || 'El celular es requerido',
          cellphone => (
            !isNaN(cellphone) ||
              cellphone.length > 9 ||
              cellphone.length < 11 ||
              !Number.isInteger(cellphone)) ||
            'El formato del celular es incorrecto'
        ]
      }
    }
  },
  async created () {
    await this.getUserInfo(this.$route.params.identificacion)
    await this.fillCategories()
  },
  computed: {
    ...mapState(['showNotificationDialog'])
  },
  methods: {
    ...mapMutations(['setShowNotificationDialog']),
    async submitGiftedCard () {
      this.loaders.giftCard = true
      try {
        const request = await UserRepository.receiver(this.getReceiverUserInfo())
        this.lastCreatedUser = request.data.data
        await CardRepository.gift(this.getCardInfo(this.lastCreatedUser.id))
        this.cardHasBeenSuccessfullyGifted = true
      } catch (error) {
        this.errors = JSON.parse(error.request.response)
      }
      this.loaders.giftCard = false
    },
    async getUserInfo (userId) {
      try {
        const user = await UserRepository.show(userId)
        this.user = user.data.data
        this.user.cards = this.filterNotGivenAwayCards(this.user.cards)
        if (this.user.cards.length === 0) {
          this.errors.message = 'Este usuario no tiene tarjetas para regalar'
        }
      } catch (error) {
        this.errors.message = 'Este usuario no existe'
      }
    },
    getReceiverUserInfo () {
      return {
        nombre: this.giftCardForm.name,
        celular: this.giftCardForm.cellphone,
        correo: this.giftCardForm.email
      }
    },
    getCardInfo (idReceiverUser) {
      return {
        id_tarjeta: this.selectedIdCard,
        id_usuario_comprador: this.$route.params.identificacion,
        id_usuario_receptor: idReceiverUser,
        mensaje: this.giftCardForm.message,
        email_alternativo: this.giftCardForm.email
      }
    },
    selectCard (cardId) {
      this.selectedIdCard = cardId
    },
    checkSelectedCard () {
      if (this.selectedIdCard === null) {
        this.setShowNotificationDialog(true)
        this.dialog.message = 'Por favor, selecciona una tarjeta'
        return false
      }
      this.step = 3
    },
    filterNotGivenAwayCards (cards) {
      return cards.filter((card) => {
        return card.id_usuario_receptor === null
      })
    },
    checkGiftedPersonValues () {
      this.loaders.continue = true
      if (
        this.giftCardForm.name === '' ||
        this.giftCardForm.cellphone === '' ||
        !this.$refs.form.validate()
      ) {
        this.dialog.message = 'Hay errores en el formulario'
        this.setShowNotificationDialog(true)
        this.loaders.continue = false
        return false
      }
      if (this.giftCardForm.email.replace(/\s/g, '') === this.user.correo.replace(/\s/g, '')) {
        this.dialog.message = '¡No puedes ingresar tu propio correo!'
        this.setShowNotificationDialog(true)
        this.loaders.continue = false
        return false
      }
      if (this.giftCardForm.cellphone.replace(/\s/g, '') === this.user.celular.replace(/\s/g, '')) {
        this.dialog.message = '¡No puedes ingresar tu propio número de celular!'
        this.setShowNotificationDialog(true)
        this.loaders.continue = false
        return false
      }
      this.loaders.continue = false
      this.step = 4
    },
    handleOTPRequested (payload) {
      this.setShowNotificationDialog(true)
      this.dialog.message = payload.otpResponseMessage
    },
    handleOTPValidated (payload) {
      if (payload.success) {
        this.step = 2
        return ''
      }
      this.setShowNotificationDialog(true)
      this.dialog.message = payload.otpResponseMessage
    },
    async fillCategories () {
      try {
        const request = await CategoryRepository.get()
        this.categories.all = request.data.data
      } catch (e) {
      }
    },
    async fillMessages (categoryID) {
      this.categories.loader = true
      try {
        const request = await CategoryRepository.messages(categoryID)
        this.categories.messages = request.data.data.messages
      } catch (e) {}
      this.categories.loader = false
    },
    appendMessage (message) {
      this.giftCardForm.message = this.giftCardForm.message + message
      this.messages.dialog = false
      this.categories.messageHasBeenSelected = true
    }
  }
}
</script>
<style scoped>
.page-header {
  padding-top: 10px;
  padding-bottom: 30px;
  border-bottom: solid 1.2px #FF80AB;
}

.card {
  transition: all 0.2s ease;
}

.card:hover {
  cursor: pointer;
  transform: scale(0.9, 0.9);
  transition: all 0.2s ease;
}

#sellCardContainer {
  max-width: 900px !important;
}

.message {
  cursor: pointer;
}
</style>
