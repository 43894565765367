<template>
  <v-card-text>
    <v-form v-model="formIsValid">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="updateUserForm.name"
              label="Nombre"
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-account
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="updateUserForm.identification"
              label="Identificación"
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-card-account-details
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="updateUserForm.email"
              label="Correo"
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-at
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="updateUserForm.cellphone"
              label="Celular"
              disabled
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-phone-plus
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <base-submit-button
              text-content="Actualizar Información del cliente"
              :loader="updateUserLoader"
              @submission="submitUpdateClient()"
            ></base-submit-button>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>

<script>
import BaseSubmitButton from '@/components/form/BaseSubmitButton'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import ErrorHelper from '@/helpers/ErrorHelper'

const CardRepository = RepositoryFactory.get('card')
const UserRepository = RepositoryFactory.get('user')
export default {
  name: 'UpdateUserInfoFormWindow',
  components: {
    BaseSubmitButton
  },
  props: {
    userInformation: {
      type: Object,
      required: true
    },
    redemptionCode: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.fillClientInfo()
  },
  data () {
    return {
      formIsValid: false,
      updateUserLoader: false,
      updateUserForm: {
        name: '',
        identification: '',
        email: ''
      }
    }
  },
  methods: {
    async submitUpdateClient () {
      this.updateUserLoader = true
      try {
        await UserRepository.update(this.getUserData())
        const card = await CardRepository.show(this.redemptionCode)
        this.$emit('user-updated', {
          success: true,
          card: card.data.data,
          message: 'Se ha actualizado la información del cliente',
          closeCardWindows: false
        })
      } catch (error) {
        this.$emit('user-updated', {
          success: false,
          message: ErrorHelper.formatErrorsAsArray(error)[0],
          closeCardWindows: false
        })
        this.scrollToBottom()
      }
      this.updateUserLoader = false
    },
    getUserData () {
      return {
        id: this.userInformation.id,
        nombre: this.updateUserForm.name,
        identificacion: this.updateUserForm.identification,
        celular: this.updateUserForm.cellphone,
        correo: this.updateUserForm.email
      }
    },
    fillClientInfo () {
      this.updateUserForm.name = this.userInformation.nombre
      this.updateUserForm.identification = this.userInformation.identificacion
      this.updateUserForm.cellphone = this.userInformation.celular
      this.updateUserForm.email = this.userInformation.correo
    }
  }
}
</script>

<style scoped>

</style>
