<template>
  <v-text-field
    v-model="formattedValue"
    :rules="validations"
    label="Valor"
    required
    @input="formatCardValue"
    inputmode="numeric"
  >
    <v-icon slot="prepend" :color="baseColors.main">
      mdi-currency-usd
    </v-icon>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseCurrencyValue',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      rawValue: this.value
    }
  },
  computed: {
    formattedValue: {
      get () {
        return this.rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      set (value) {
        this.rawValue = value.replace(/[^\d]/g, '')
      }
    },
    validations () {
      return [
        value => /^\d+(,\d+)*$/.test(value) || 'El valor no debe contener caracteres especiales'
      ]
    }
  },
  methods: {
    formatCardValue () {
      this.rawValue = this.rawValue.replace(/[^\d]/g, '')
      this.$emit('value-changed', this.rawValue)
    }
  }
}
</script>

<style scoped>

</style>
