<template>
  <div class="page-header mt-10 mb-5">
    <v-card  flat class="pl-13" :style="{'border-left': 'solid 5px #F58FB1' }">
      <v-row>
        <v-col
          cols="12"
          md="10"
          lg="10"
          class="main-font"
        >
          <h1 class="main-font">
            <slot name="text"></slot>
          </h1>
          <span class="text-muted">
            <slot name="description"></slot>
          </span>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="12"
          md="2"
          lg="2">
          <v-avatar
            class="profile"
            :color="baseColors.main"
            size="110"
            tile
          >
            <v-icon dark size="60px">
              <slot name="icon"></slot>
            </v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ThePageHeader'
}
</script>
<style scoped>
  .page-header-card-container{
    border-left: solid 5px #FF80AB;
    padding-left: 19px;
  }
</style>
