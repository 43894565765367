<template>
  <v-card-text>
    <v-form
      ref="redemptionForm"
      v-model="formIsValid"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="redemptionForm.description"
              label="Descripción"
            >
              <v-icon slot="prepend" :color="baseColors.main">
                mdi-pencil-box
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <base-currency-value
              :value="redemptionForm.total"
              @value-changed="changeTotal"
              :key="valueInputReset"
            ></base-currency-value>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="redemptionForm.reference"
              label="Referencia (Opcional)"
            >
              <v-icon slot="prepend" :color="baseColors.main">
                mdi-list-box-outline
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="d-flex justify-end flex-wrap">
            <base-submit-button
              text-content="Registrar"
              :loader="redemptionFormLoader"
              @submission="submitRedemption()"
            ></base-submit-button>
            <v-btn
              color="white"
              tile
              @click="reset()"
            >
              Limpiar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>

<script>
import BaseCurrencyValue from '@/components/form/BaseCurrencyValue'
import BaseSubmitButton from '@/components/form/BaseSubmitButton'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import ErrorHelper from '@/helpers/ErrorHelper'

const CardRepository = RepositoryFactory.get('card')
export default {
  name: 'RedeemCardFormWindow',
  props: {
    redemptionCode: {
      type: String,
      required: true
    }
  },
  components: {
    BaseCurrencyValue,
    BaseSubmitButton
  },
  data () {
    return {
      redemptionFormLoader: false,
      valueInputReset: 0,
      formIsValid: false,
      redemptionForm: {
        reference: '',
        total: '',
        description: ''
      }
    }
  },
  methods: {
    changeTotal (newValue) {
      this.redemptionForm.total = newValue
    },
    reset () {
      this.$refs.redemptionForm.reset()
    },
    async submitRedemption () {
      if (this.$refs.redemptionForm.validate()) {
        this.redemptionFormLoader = true
        try {
          const redeemedCard = await CardRepository.redeem(this.getRedeemData())
          const card = redeemedCard.data.data
          this.$refs.redemptionForm.reset()
          this.redemptionForm.total = ''
          this.valueInputReset += 1
          const formattedBalance = this.$options.filters.toCurrency(card.saldo)
          this.$emit('card-redeemed', {
            success: true,
            card: card,
            message: `Se ha redimido la tarjeta correctamente.
            El saldo actual de la tarjeta es: ${formattedBalance}.`,
            closeCardWindows: true
          })
        } catch (error) {
          this.scrollToBottom()
          this.$emit('card-redeemed', {
            success: false,
            message: ErrorHelper.formatErrorsAsArray(error)[0],
            closeCardWindows: false
          })
        }
        this.redemptionFormLoader = false
      }
    },
    getRedeemData () {
      return {
        codigo_redencion: this.redemptionCode,
        valor: this.redemptionForm.total,
        descripcion: this.redemptionForm.description,
        referencia: this.redemptionForm.reference
      }
    }
  }
}
</script>

<style scoped>

</style>
