import { post } from '@/api'

const resource = 'otp'

export default {
  async generate (data) {
    return post(`${resource}/generate`, data)
  },
  async redeem (data) {
    return post(`${resource}/redeem`, data)
  }
}
