import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import router from '@/router'
import ErrorHelper from '@/helpers/ErrorHelper'

const AuthRepository = RepositoryFactory.get('auth')

export default {
  namespaced: true,
  state: {
    store: null,
    loginErrors: [],
    accessToken: null,
    logoutLoader: false,
    authenticated: false,
    preLoginStoreId: null,
    preLoginSuccessful: false
  },
  mutations: {
    setAuthenticated (state, value) {
      state.authenticated = value
    },
    setStore (state, value) {
      state.store = value
    },
    setLoginErrors (state, value) {
      state.loginErrors = value
    },
    setPreLoginSuccessful (state, value) {
      state.preLoginSuccessful = value
    },
    setPreLoginStoreId (state, value) {
      state.preLoginStoreId = value
    },
    setAccessToken (state, value) {
      state.accessToken = value
    },
    setLogoutLoader (state, value) {
      state.logoutLoader = value
    }
  },
  actions: {
    async preLogin ({ commit }, credentials) {
      return AuthRepository.preLogin(credentials).then(response => {
        commit('setPreLoginSuccessful', response.data.data.success)
        commit('setLoginErrors', [])
        commit('setPreLoginStoreId', response.data.data.storeId)
      }).catch(error => {
        commit('setLoginErrors', ErrorHelper.formatErrorsAsArray(error))
        commit('setPreLoginSuccessful', false)
        commit('setPreLoginStoreId', null)
      })
    },
    async login ({ commit }, credentialsWithOTP) {
      return AuthRepository.login(credentialsWithOTP).then(response => {
        commit('setLoginErrors', [])
        commit('setAuthenticated', true)
        commit('setStore', response.data.data.store)
        commit('setAccessToken', response.data.data.token)
        localStorage.setItem('accessToken', response.data.data.token)
        router.go(0)
      }).catch(error => {
        commit('setLoginErrors', ErrorHelper.formatErrorsAsArray(error))
        commit('setStore', null)
        commit('setAuthenticated', false)
      })
    },
    async logout ({ commit }) {
      commit('setLogoutLoader', true)
      try {
        await AuthRepository.logout()
      } catch (e) {}
      commit('setLogoutLoader', false)
      commit('setStore', null)
      commit('setLoginErrors', null)
      commit('setAccessToken', null)
      commit('setAuthenticated', false)
      localStorage.removeItem('accessToken')
      router.push('/login').catch(e => console.warn(e))
    }
  }
}
