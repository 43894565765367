<template>
  <v-card
    class="mt-16">
    <v-tabs
      v-model="tab"
      :background-color="baseColors.main"
      centered
      dark
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-type">
        Tipo
        <v-icon>mdi-gift</v-icon>
      </v-tab>

      <v-tab href="#tab-info" :disabled="!selectedCardType">
        Información
        <v-icon>mdi-account-check</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-type">
        <sell-card-form-select-type
          :key="vItemKey"
          :card-types="cardTypes"
          @card-type-selected="selectCardType"
        ></sell-card-form-select-type>
      </v-tab-item>
      <v-tab-item value="tab-info">
        <sell-card-form-user-info
          :store-id="store.id"
          :selected-card-type="selectedCardType"
          @card-sold-attempt="cardSoldAttempt"
        ></sell-card-form-user-info>
      </v-tab-item>
    </v-tabs-items>
    <base-notification-dialog></base-notification-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseNotificationDialog from '@/components/notifications/BaseNotificationDialog'
import SellCardFormSelectType from '@/components/card/sell/SellCardFormSelectType'
import SellCardFormUserInfo from '@/components/card/sell/SellCardFormUserInfo'

export default {
  name: 'SellCardForm',
  components: {
    SellCardFormUserInfo,
    SellCardFormSelectType,
    BaseNotificationDialog
  },
  data () {
    return {
      tab: {},
      vItemKey: 0,
      selectedCardType: null,
      cardTypes: [{
        name: 'Regalo',
        icon: 'mdi-hand-heart'
      }, {
        name: 'Tiquetera',
        icon: 'mdi-ticket'
      }]
    }
  },
  computed: {
    ...mapState('auth', ['store'])
  },
  methods: {
    ...mapActions('notifications', ['displayNotificationDialogWithMessage']),
    selectCardType (payload) {
      this.selectedCardType = payload
      this.tab = 'tab-info'
    },
    cardSoldAttempt (payload) {
      if (payload.success) {
        this.tab = 'tab-type'
        this.selectedCardType = null
        this.vItemKey += 1
      }
      this.displayNotificationDialogWithMessage(payload.message)
    }
  }
}
</script>

<style scoped>

</style>
