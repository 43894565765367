<template>
  <v-card-text>
    <v-list shaped>
      <v-subheader><h2>Información de la tarjeta</h2></v-subheader>
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gift-open-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Fecha de compra: <span class='pink--text'>{{ card.fecha_compra | toDate }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Por un valor inicial de: <span class="pink--text">{{ card.valor | toCurrency }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-beer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Actualmente cuenta con un saldo de: <span class="pink--text">{{ card.saldo | toCurrency }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-sale</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="card.descuento > 0">
              Cuenta con un descuento de: <span class="pink--text">{{ card.descuento }}%</span>
            </v-list-item-title>
            <v-list-item-title v-else>
              No cuenta con ningún descuento
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-food</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Y fue redimida por última vez: <span class="pink--text">{{
                card.fecha_ultima_redencion | toDate
              }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card-text>
</template>

<script>
export default {
  name: 'CardInfoWindow',
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
