<template>
  <div>
    <the-page-header>
      <template v-slot:icon>mdi-food</template>
      <template v-slot:text>Redimir tarjeta de regalo</template>
      <template v-slot:description>
        Puedes buscar las tarjetas/tiqueteras por usuario, introduciento ya sea su teléfono, correo o identificación. También
        puedes introducir directamente el código de redención de la tarjeta.
      </template>
    </the-page-header>
    <search-cards-form
      v-if="displaySearchCardForm"
      :store-id="store.id"
      :store-group-id="store.id_grupo_comercio"
      @card-selected="showCardInfo"
      @validate-data-request="displayCardInfo = false"
    ></search-cards-form>
    <v-btn v-else class="mt-2" :color="baseColors.main" dark @click="displaySearchCardForm = true">
      <v-icon color="white">
        mdi-keyboard-backspace
      </v-icon>
       Buscar otra tarjeta
    </v-btn>
    <redeem-card-windows
      v-if="displayCardInfo"
      :card="selectedCard"
      @close-card-windows="closeCardInfo"
    ></redeem-card-windows>
  </div>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader'
import SearchCardsForm from '@/components/card/redeem/SearchCardsForm'
import { mapState } from 'vuex'
import RedeemCardWindows from '@/components/card/redeem/RedeemCardWindows'

export default {
  name: 'RedeemCard',
  components: {
    RedeemCardWindows,
    ThePageHeader,
    SearchCardsForm
  },
  data () {
    return {
      selectedCard: {},
      displayCardInfo: false,
      displaySearchCardForm: true
    }
  },
  computed: {
    ...mapState('auth', ['store'])
  },
  methods: {
    showCardInfo (payload) {
      this.selectedCard = payload.card
      this.displayCardInfo = true
      this.displaySearchCardForm = false
    },
    closeCardInfo (payload) {
      this.displayCardInfo = !payload
      this.displaySearchCardForm = true
    }
  }
}
</script>

<style scoped>

</style>
