export default {
  namespaced: true,
  state: {
    displayNotificationDialog: false,
    notificationDialogMessage: ''
  },
  mutations: {
    setDisplayNotificationDialog (state, value) {
      state.displayNotificationDialog = value
    },
    setNotificationDialogMessage (state, value) {
      state.notificationDialogMessage = value
    }
  },
  actions: {
    displayNotificationDialogWithMessage ({ commit }, message) {
      if (message !== '') {
        commit('setDisplayNotificationDialog', true)
        commit('setNotificationDialogMessage', message)
      }
    },
    closeNotificationDialog ({ commit }) {
      commit('setDisplayNotificationDialog', false)
    }
  }
}
