import axios from 'axios'
// Defining Base URL and base Raw Url
const baseUrl = process.env.VUE_APP_API_URL
const baseRawUrl = process.env.VUE_APP_URL
axios.defaults.withCredentials = true

const token = localStorage.getItem('accessToken')
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

// Checking if the user is unauthorized, if so then logout
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken')
    window.location.href = '/login'
  } else {
    return Promise.reject(error)
  }
})

export function get (url, data, useRaw = false) {
  return axios({
    method: 'GET',
    baseURL: !useRaw ? baseUrl : baseRawUrl,
    url: url,
    params: data
  })
}

export function post (url, data, useRaw = false, hasImages = false) {
  const headers = {}

  if (hasImages) {
    headers['Content-Type'] = 'multipart/form-data'
  }
  return axios({
    method: 'POST',
    baseURL: !useRaw ? baseUrl : baseRawUrl,
    url: url,
    data: data,
    headers: headers
  })
}

export function put (url, data, useRaw = false) {
  return axios({
    method: 'PUT',
    baseURL: !useRaw ? baseUrl : baseRawUrl,
    url: url,
    data: data
  })
}
