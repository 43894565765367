<template>
  <v-card flat>
    <v-card-text>
      <h3 class="text-center main-font mb-3">
        Selecciona el tipo de tajeta que quieres vender:
      </h3>
      <v-item-group>
        <v-container>
          <v-row>
            <v-col
              v-for="cardType in cardTypes"
              :key="cardType.name"
              cols="6"
              md="6"
              sm="6"
            >
              <v-item v-slot="{ active, toggle }">
                <v-card
                  :color="active ? baseColors.main : 'white'"
                  class="d-flex align-center"
                  :style="active ? 'color: white': 'color: black'"
                  height="200"
                  @click="[toggle(), selectCardType(cardType.name, active)]"
                  elevation="5"
                >
                  <v-scroll-y-transition>
                    <div
                      class="text-h3 flex-grow-1 text-center"
                    >
                      <v-icon
                        :color="active ? 'white' : baseColors.main"
                        size="40"
                      > {{ cardType.icon }}
                      </v-icon>
                      <br>
                      <span class="text-h6">{{ cardType.name }}</span>
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SellCardFormSelectType',
  props: {
    cardTypes: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectCardType (carType, active) {
      this.$emit('card-type-selected', !active ? carType : null)
      return ''
    }
  }
}
</script>

<style scoped>

</style>
