import { get } from '@/api'

const resource = 'category'

export default {
  async get () {
    return get(`${resource}`, {})
  },
  async messages (categoryID) {
    return get(`${resource}/${categoryID}`, {})
  }
}
