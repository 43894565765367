import './assets/css/main.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import store from './store'

Vue.prototype.moment = moment
moment.locale('es')
Vue.config.productionTip = false
Vue.mixin({
  data () {
    return {
      baseURL: process.env.VUE_APP_API_URL,
      baseColors: {
        main: 'pink accent-1',
        secondary: 'light-blue lighten-2',
        text: 'white--text'
      }
    }
  },
  methods: {
    scrollToBottom () {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
      }, 500)
    },
    scrollToElement (element, timeout = 200) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' })
      }, timeout)
    }
  }
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number' && isNaN(parseFloat(value))) {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})

Vue.filter('toDate', function (value) {
  if (value === null) {
    return 'No Registra'
  }
  return moment(value).format('MMMM Do YYYY, h:mm:ss a')
})

Vue.filter('mask', function (value) {
  return value.substring(0, 4) + '*'.repeat(10)
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
