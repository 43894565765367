<template>

  <v-app>
    <the-sidenav :toggle="drawer"></the-sidenav>
    <v-main>
      <v-container
        class="mx-auto my-2"
        id="sellCardContainer"
      >
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TheSidenav from '@/components/TheSidenav'
export default {
  name: 'App',
  components: {
    TheSidenav
  },
  data: () => ({
    drawer: null
  })
}
</script>
