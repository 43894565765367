<template>
  <div>
    <the-page-header>
      <template v-slot:icon>mdi-wallet-giftcard</template>
      <template v-slot:text>Reporte de redenciones</template>
      <template v-slot:description>
        Aquí podrás gestionar todo lo relacionado con las redenciones que se hayan realizado en tu tienda.
        Puedes filtrar por tipo de tarjeta o por un rango de fechas en específico.
      </template>
    </the-page-header>
    <v-row class="mt-16">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-text>
            <redemption-list></redemption-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader'
import RedemptionList from '@/components/store/redemptions/RedemptionList'
export default {
  name: 'StoreRedemptions',
  components: {
    RedemptionList,
    ThePageHeader
  }
}
</script>

<style scoped>

</style>
