<template>
  <div>
    <v-app-bar
      app
      color="grey lighten-5"
      dense
    >
      <v-app-bar-nav-icon v-if="canShowSidebar" @click.stop="navToggle = !navToggle"></v-app-bar-nav-icon>
      <v-toolbar-title>MiTarjetero: Tiqueteras y Tarjetas Regalo</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="navToggle"
      app
      :class="[baseColors.main, 'main-font']"
    >
      <template v-slot:prepend>
        <v-list-item>
          <v-btn
            icon
            @click.stop="navToggle = !navToggle"
            color="white"
            class="ml-auto"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item class="text-center">
          <v-list-item-content>
            <v-icon size="100px" :class="baseColors.text">
              mdi-store
            </v-icon>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="text-center" :class="baseColors.text">
          <v-list-item-content>
            <v-list-item-title><strong>{{ loggedStoreData.name }}</strong></v-list-item-title>
            <v-list-item-subtitle :class="baseColors.text">{{ loggedStoreData.group.name }}</v-list-item-subtitle>
            <v-list-item-subtitle :class="baseColors.text">{{ loggedStoreData.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="grey lighten-5"></v-divider>
        <v-list dense class="navContentList">
          <v-list-item
            :class="baseColors.text"
            v-for="item in items"
            v-bind:key="item.title"
            link
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon :class="baseColors.text">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <v-divider class="grey lighten-5"></v-divider>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            color="white"
            style="color: black !important;"
            class="mb-2"
            @click="logout"
            :loading="logoutLoader"
          >
            Cerrar sesión
          </v-btn>
          <v-btn
            block
            @click.stop="navToggle = !navToggle"
            color="white"
            style="color: black !important;"
          >
            Cerrar Ventana
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TheSidenav',
  data () {
    return {
      navToggle: false,
      canShowSidebar: false,
      nonSideBarViews: [
        'GiftCard',
        'Login'
      ],
      items: [
        {
          title: 'Vender',
          icon: 'mdi-gift',
          to: '/'
        },
        {
          title: 'Redimir',
          icon: 'mdi-hamburger',
          to: '/redeem'
        },
        {
          title: 'Ventas',
          icon: 'mdi-cash-multiple',
          to: '/sales'
        },
        {
          title: 'Redenciones',
          icon: 'mdi-wallet-giftcard',
          to: '/redemptions'
        },
        {
          title: 'Referidos',
          icon: 'mdi-home-group-plus',
          to: '/referrals'
        }
      ]
    }
  },
  created () {
    this.navToggle = this.viewHasSidebar(this.$route.name)
    this.canShowSidebar = this.viewHasSidebar(this.$route.name)
  },
  computed: {
    ...mapState('auth', ['store', 'logoutLoader']),
    loggedStoreData () {
      if (!this.store) {
        return {
          name: '',
          email: '',
          group: {
            name: ''
          }
        }
      }
      return {
        name: this.store.nombre,
        email: this.store.correo,
        group: {
          name: this.store.group ? this.store.group.nombre : ''
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    viewHasSidebar (view) {
      return !this.nonSideBarViews.includes(view)
    }
  },
  watch: {
    '$route.name': function (newRoute, oldRoute) {
      this.navToggle = this.viewHasSidebar(newRoute)
      this.canShowSidebar = this.viewHasSidebar(this.$route.name)
    }
  }
}
</script>

<style scoped>
.navContentList {
  margin-top: 20vh;
}
</style>
