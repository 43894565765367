<template>
  <v-form
    v-model="formIsValid"
    @submit.prevent="sellCard"
    ref="form"
  >
    <v-card :loading="formIsLoading">
      <template slot="progress">
        <v-progress-linear
          :color="baseColors.secondary"
          height="3"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-text>
        <h3 class="text-center main-font mb-3">
          Estás vendiendo una tarjeta de tipo: <strong> {{selectedCardType}} </strong>
        </h3>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="sellCardForm.cellphone"
              :rules="validations.cellphone"
              :counter="11"
              label="Celular"
              @blur="checkAndSetUserIfExists()"
              required
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-cellphone-check
              </v-icon>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="sellCardForm.name"
              :rules="validations.name"
              label="Nombre"
              required
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-account-check
              </v-icon>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="sellCardForm.id"
              :rules="validations.identification"
              :counter="12"
              label="Identificación"
              required
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-card-account-details
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4">
            <v-text-field
              v-model="sellCardForm.email"
              :rules="validations.email"
              label="Correo"
              required
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-at
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4">
            <base-currency-value
              :value="sellCardForm.value"
              @value-changed="changeCardValue"
              :key="vItemKey"
            ></base-currency-value>
          </v-col>
          <v-col
            cols="12"
            md="4">
            <v-select
              v-if="selectedCardType === 'Tiquetera'"
              v-model="sellCardForm.discount"
              :items="discountValues"
              label="Descuento"
            >
              <v-icon
                slot="prepend"
                :color="baseColors.main"
              >
                mdi-percent
              </v-icon>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          type="submit"
          :color="baseColors.main"
          :loading="formIsLoading"
          :disabled="!formIsValid || selectedCardType === null"
          dark
        >
          Vender tarjeta
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { sellCardValidations } from '@/validations/sellCardValidation'
import ErrorHelper from '@/helpers/ErrorHelper'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import BaseCurrencyValue from '@/components/form/BaseCurrencyValue'

const UserRepository = RepositoryFactory.get('user')
const CardRepository = RepositoryFactory.get('card')

export default {
  name: 'SellCardFormUserInfo',
  components: {
    BaseCurrencyValue
  },
  props: {
    storeId: {
      type: Number,
      required: true
    },
    selectedCardType: {
      required: true
    }
  },
  data () {
    return {
      vItemKey: 0,
      message: null,
      success: false,
      userExist: false,
      formIsValid: false,
      formIsLoading: false,
      preFilledUser: {},
      sellCardForm: {
        id: '',
        name: '',
        email: '',
        cellphone: '',
        value: '',
        discount: 0
      },
      discountValues: Array.from({ length: 99 }, (_, index) => index),
      validations: sellCardValidations
    }
  },
  methods: {
    changeCardValue (newValue) {
      this.sellCardForm.value = newValue
    },
    async sellCard () {
      this.formIsLoading = true
      try {
        const user = this.userExist
          ? await UserRepository.update(Object.assign({ id: this.preFilledUser.id }, this.userData()))
          : await UserRepository.create(this.userData())
        this.preFilledUser = this.userExist ? this.preFilledUser : user.data.data
        await CardRepository.sell(this.cardData(this.preFilledUser.id))
        this.reset()
        this.$refs.form.resetValidation()
        this.vItemKey += 1
        this.message = '¡Se ha vendido la tajeta satisfatoriamente!'
        this.success = true
      } catch (error) {
        this.success = false
        this.formIsLoading = false
        console.warn(error)
        this.message = ErrorHelper.formatErrorsAsArray(error)[0]
      }
      this.$emit('card-sold-attempt', { success: this.success, message: this.message })
      this.formIsLoading = false
    },
    async checkAndSetUserIfExists () {
      this.formIsLoading = true
      this.userExist = false
      try {
        const user = await UserRepository.show(this.sellCardForm.cellphone)
        if (user.data.data !== null) {
          this.preFilledUser = user.data.data
          this.autoCompleteUser(this.preFilledUser)
          this.userExist = true
        }
      } catch (error) {
        this.userExist = false
      }
      this.formIsLoading = false
      return this.userExist
    },
    autoCompleteUser (user) {
      this.sellCardForm.name = user.nombre ?? ''
      this.sellCardForm.id = user.identificacion ?? ''
      this.sellCardForm.email = user.correo ?? ''
      this.sellCardForm.cellphone = user.celular ?? ''
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      Object.keys(this.sellCardForm).forEach(key => {
        this.sellCardForm[key] = ''
      })
      this.sellCardForm.discount = 0
    },
    userData () {
      return {
        nombre: this.sellCardForm.name,
        identificacion: this.sellCardForm.id,
        celular: this.sellCardForm.cellphone,
        correo: this.sellCardForm.email
      }
    },
    cardData (userID) {
      return {
        id_comercio: this.storeId,
        id_usuario_comprador: userID,
        valor: this.sellCardForm.value,
        saldo: this.sellCardForm.value,
        tipo: this.selectedCardType,
        id_usuario_receptor: this.selectedCardType === 'Regalo' ? null : userID,
        descuento: this.selectedCardType === 'Tiquetera' ? this.sellCardForm.discount : 0
      }
    }
  }
}
</script>

<style scoped>

</style>
